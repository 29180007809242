import React from 'react'

const Hero = ({ title, description, featured }) => {

  return (
    <section className='mt-8 md:my-24'>
      <div className='flex flex-col-reverse justify-between gap-4 text-white md:gap-16 md:flex-row md:px-12'>
        <div
          className='flex-1 p-4 mb-8 md:p-0 md:mb-0'
        >
          <h1 className='mb-8 text-3xl font-black uppercase md:text-6xl'>{title}</h1>
          <div>{description}</div>
        </div>
        <div className='flex-1 p-4'>
          { featured }
        </div>
      </div>
    </section>
  )
}

export default Hero
